import {
  ButtonVariant,
  type ButtonTheme,
} from '@/components/brochureV2/primitive/Button/types';
import {PHtml} from '@/components/shared/HtmlElements';
import type {ImageComponent, LinkComponent} from '@/types';
import Link from '@/components/brochureV2/primitive/Link/Link';
import Image from '@/components/shared/Image/Image';
import {type SPACER_SIZES} from '@/components/brochureV2/layout/Spacer/Spacer';

export interface ImageWithCaptionProps {
  contentHtml?: string;
  image?: ImageComponent;
  link?: LinkComponent;
  buttonTheme: ButtonTheme;
  topSpacerSize?: (typeof SPACER_SIZES)[number];
  bottomSpacerSize?: (typeof SPACER_SIZES)[number];
}

export default function ImageWithCaption({
  buttonTheme,
  image,
  contentHtml,
  link,
}: ImageWithCaptionProps) {
  return (
    <figure>
      {image?.srcSet && (
        <Image
          className="w-[100%]"
          alt={image.alt}
          sizes={image.sizes}
          srcSet={image.srcSet}
          loading="lazy"
        />
      )}
      {(contentHtml || link) && (
        <figcaption>
          {contentHtml && (
            <PHtml className="text-body-sm mt-1 md:mt-2">{contentHtml}</PHtml>
          )}
          {link?.url && (
            <Link
              href={link.url}
              componentName={link.name || ''}
              variant={ButtonVariant.Secondary}
              theme={buttonTheme}
              className="mt-2 md:mt-4"
            >
              {link.text}
            </Link>
          )}
        </figcaption>
      )}
    </figure>
  );
}
